<template>
<div>
    <FeatureView />
</div>
</template>

<script>
import FeatureView from '../components/dashboard/FeatureView.vue'
export default {
    components: {
        FeatureView
    }
}
</script>

<style>
