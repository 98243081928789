<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
        <Footer slot="footer" />
        <div slot="content">
            <!-- <input type="text" v-model="filters.name.value"> -->
            <div class="container-fluid">
                <div class="mt-4 mb-3" style="display:flex;min-height:40px; align-items:center">
                    <div>
<img src="../../assets/images/transferbtnBig.png" height="40"  class="mr-3" >
<span style="font-size:18px"><b> Transfer Investments</b></span>

                    </div>

                </div>
                <div class="mb-3">
<p style="font-size:15px">You can now transfer investments to other people whether they are on reQuid or not.</p>

</div>
<div class="text-right mb-2">
                     <img src="../../assets/images/tryit.png" class="point-cursor" height="30"  @click="gotoPortfolio">

</div>
                   <img src="../../assets/images/portfolioGraphDesk.png"  class="mb-2 img-fluid" v-if="windowWidth > 767">
                         <img src="../../assets/images/portfolioGraph.png"  class="mb-2 img-fluid" v-if="windowWidth < 768">

                  <!---  <img src="../../assets/images/tap2.png" height="30" v-if="loader">
                    <img src="../../assets/images/tap.png" height="50" @click="getNumber" v-if="firstPhase" style="cursor:pointer">
                  -->
               

            </div>
        </div>
    </Wrapper>
</div>
</template>

<script>
import Navbar from '../partials/Navbar'
import Sidebar from '../partials/Sidebar'
import Footer from '../partials/Footer'
import Wrapper from '../partials/Wrapper'
import trans from "../../../translations";

import {
    commonJs
} from './mixins/commonJs.js';
import {
    mapState
} from "vuex";
import PictureInput from 'vue-picture-input'

export default {
   mixins: [commonJs],
    data() {

        return {
            tran: {},
            giftItem: '',
           
            firstPhase:true,
            lastStage: false

        }
    },

    methods: {

        getNumber() {

            this.loader = true
 this.firstPhase = false
            setTimeout(() => {
                this.loader = false
this.lastStage = true
                this.$confetti.start();
                let giftArray = ["N50", "N200", "N100", "N200", "N100", "N1000", "N100", "500", "N200", "N50", "N200", "N100", "N50", "N100", "N200"]

                this.giftItem = giftArray[Math.floor(Math.random() * giftArray.length)];

            }, 10000)

        },

        sendGuestTosignUp() {
            this.$router.push("/signup");
        },
gotoPortfolio(){

    this.$router.push('/portfolio')
},
        stopConfetti(){
  this.$confetti.stop();

        }

    },
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
   
    },

    computed: {
        ...mapState({

            accountOverview: state => state.market.accountOverview,

        }),

        returnBankSelected() {

            return this.bankSelected
        },
        returnFirstName() {

            return this.firstName
        },
        returnLastName() {

            return this.lastName
        },
    },
    mounted() {

        this.tran = trans;

        let heading = {

            main: 'New Features',
        }

        this.$store.commit('market/savePageHeading', heading)
     

    }

}
</script>

<style scoped>
.centered {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(-10px) contrast(-20);
}

.blob-1,
.blob-2 {
    width: 30px;
    height: 30px;
    position: absolute;
    background:  #FFB100;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blob-1 {
    left: 20%;
    animation: osc-l 2.5s ease infinite;
}

.blob-2 {
    left: 80%;
    animation: osc-r 2.5s ease infinite;
    background: radial-gradient(97.36% 94.38% at 9% 7.13%, #4DFF79 0%, #0094FF 68.22%);
}

@keyframes osc-l {
    0% {
        left: 20%;
    }

    50% {
        left: 50%;
    }

    100% {
        left: 20%;
    }
}

@keyframes osc-r {
    0% {
        left: 80%;
    }

    50% {
        left: 50%;
    }

    100% {
        left: 80%;
    }
}
</style>
